const App = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800">
          Welcome to Everest Radiator
        </h1>
        <p className="mt-4 text-lg text-gray-600">
          Site Under Construction Soon In Production
        </p>
        <p className="mt-4 text-lg text-gray-600">Contact: +91 93842 17772</p>
      </div>
    </div>
  );
};

export default App;
